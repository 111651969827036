<template>
  <q-carousel
    v-model="curSlide"
    transition-prev="scale"
    transition-next="scale"
    animated
    control-color="white"
    navigation
    padding
    :arrows="curSlide != 'slide4'"
    :autoplay="3333"
    height="calc(100vh - 50px)"
    class="text-white shadow-1 rounded-borders"
  >
    <template v-slot:navigation-icon="{ active, btnProps, onClick }">
      <q-btn
        v-if="curSlide == 'slide4' && active"
        size="lg"
        icon="visibility"
        color="red"
        flat
        round
        dense
        @click="onClick"
      />
      <q-btn
        v-else-if="curSlide == 'slide4'"
        size="lg"
        icon="visibility"
        color="red-10"
        flat
        round
        dense
        @click="onClick"
      />
      <q-btn
        v-else
        size="sm"
        :icon="btnProps.icon"
        color="white"
        flat
        round
        dense
        @click="onClick"
      />
    </template>
    <template v-for="slide in slides" :key="slide.name">
      <q-carousel-slide :name="slide.name" class="column no-wrap flex-center">
        <q-icon :name="slide.icon" :color="slide.iconColor" size="64px" />
        <div
          class="q-mt-md text-center"
          style="font-size: 18px"
          v-html="slide.text"
        ></div>
      </q-carousel-slide>
    </template>
  </q-carousel>
</template>

<script>
import { ref } from "vue";

export default {
  name: "IntroCarousel",
  setup() {
    return {
      curSlide: ref("slide1"),
      slides: ref([
        {
          name: "slide1",
          icon: "help_outline",
          iconColor: "white",
          text: "想偷看別人裝備，但還在跟傻屌一樣瘋狂按重新整理嗎？",
        },
        {
          name: "slide2",
          icon: "cloud_download",
          iconColor: "white",
          text: "從專門設計的後台讀取所有資料，整理好呈現在您的眼前",
        },
        {
          name: "slide3",
          icon: "folder_shared",
          iconColor: "white",
          text: "我們將為每隻角色保存一個文件夾，保留所有歷史記錄",
        },
        {
          name: "slide4",
          icon: "visibility",
          iconColor: "red-10",
          text: "立即開始<span class='bstk-introKeyword'>無情跟蹤</span>您的目標",
        },
      ]),
    };
  },
};
</script>
<style lang="scss">
.bstk-introKeyword {
  font-size: 20px;
  font-weight: 700;
  color: #b71c1c;
}
</style>
