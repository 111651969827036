import firebase from "firebase/app";
import "firebase/firestore";
import { ref } from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyDQ-AYoOutcmQf-bHSo3312CXJLU_4AfWA",
  authDomain: "bnshive-firebase.firebaseapp.com",
  projectId: "bnshive-firebase",
  storageBucket: "bnshive-firebase.appspot.com",
  messagingSenderId: "178385962477",
  appId: "1:178385962477:web:40cb8e85144d95637cd3e4",
  measurementId: "G-0BSP5RYP3J",
};

const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
const db = firebaseApp.firestore();
const queryRecord = db.collection("character").doc("queryRecord_v3");

export const writeRecordToDB = async (res, minInterval = 60) => {
  // Check if data is valid
  if (!res.charExist) {
    return false;
  }
  let charId = res.info.name;
  let t = res.t;
  // Get most recent record & Check interval
  let snapshot;
  try {
    snapshot = await queryRecord
      .collection(charId)
      .orderBy("t", "desc")
      .limit(1)
      .get();
  } catch (err) {
    return `Write record (${charId}) Error: GetRecentRecordFailed\n` + err;
  }
  if (snapshot.docs.length != 0) {
    if (t - snapshot.docs[0].data().t < minInterval) {
      return `Write record (${charId}) Error: IntervalLessThanMinimum`;
    }
  }
  // Write record to DB
  return await queryRecord
    .collection(charId)
    .doc(t.toString())
    .set(res)
    .then(() => {
      return `Write record ${charId} Success`;
    })
    .catch((err) => {
      return `Write record (${charId}) Error: WriteToDBFailed\n` + err;
    });
};

export const syncRecordFromDB = (charId, maxHistory = 20) => {
  const res = ref([]);
  queryRecord
    .collection(charId)
    .orderBy("t", "asc")
    .limitToLast(maxHistory)
    .onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          res.value.unshift(change.doc.data());
        }
      });
    });
  return res;
};
