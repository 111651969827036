<template>
  <q-layout view="hHh lpr fFf">
    <q-header class="bg-dark">
      <q-toolbar class="q-px-sm">
        <q-btn
          dense
          outline
          color="secondary"
          aria-label="About"
          icon="menu_book"
          @click="openAbout = !openAbout"
          class="q-mr-sm"
        ></q-btn>
        <q-btn
          dense
          outline
          color="secondary"
          aria-label="Menu"
          :icon="openMenu ? 'menu_open' : 'menu'"
          @click="
            openMenu = !openMenu;
            if (introSkipped == 1) setSkipIntro(2);
          "
        ></q-btn>
        <q-dialog v-model="openAbout">
          <AboutDialog></AboutDialog>
        </q-dialog>
        <q-dialog v-model="openMenu" seamless position="bottom">
          <MenuDialog v-model:cfg="cfg"></MenuDialog>
        </q-dialog>
        <q-toolbar-title class="q-px-sm">劍靈跟蹤狂</q-toolbar-title>
        <div v-html="stalkCD" class="bstk-stalkCD"></div>
        <q-input
          dense
          filled
          color="secondary"
          input-class="text-right"
          input-style="font-size:15px"
          maxlength="16"
          label="大俠您想跟蹤誰呢？"
          v-model="stalkIdInput"
          :disable="startStalkLoading || stalkThreadCount >= 5"
          @keydown.enter.prevent="startStalking()"
          class="bstk-stalkIdInputBox q-ml-md"
          :class="{ expand: stalkIdInputExpand || stalkIdInput != '' }"
          @blur="stalkIdInputExpand = false"
          @focus="
            stalkIdInputExpand = true;
            if (introSkipped == 0) setSkipIntro(1);
          "
        >
          <template v-slot:append>
            <q-icon v-if="stalkIdInput === ''" name="visibility"></q-icon>
            <q-icon
              v-else
              name="close"
              class="cursor-pointer"
              @click="stalkIdInput = ''"
            ></q-icon>
          </template>
          <template v-slot:after>
            <q-btn
              color="primary"
              padding="sm md"
              :loading="startStalkLoading"
              :disable="stalkThreadCount >= 5"
              @click="startStalking()"
              class="bstk-startStalkingBtn"
            >
              <span>無情跟蹤</span>
            </q-btn>
          </template>
        </q-input>
      </q-toolbar>
    </q-header>

    <!-- Page content -->
    <q-page-container>
      <IntroCarousel v-if="stalkThreadCount == 0"></IntroCarousel>

      <!-- Tab Bar -->
      <q-tabs
        v-if="stalkThreadCount != 0"
        dense
        align="left"
        class="bg-dark"
        indicator-color="secondary"
        v-model="stalkThreadActive"
      >
        <q-tab
          no-caps
          class="q-pl-lg q-pr-sm"
          v-for="tab in stalkThreads"
          :key="tab.charId"
          :name="tab.charId"
        >
          <div>
            {{ tab.charId }}
            <q-btn
              flat
              round
              size="xs"
              icon="close"
              class="q-ml-sm"
              @click.stop="stopStalking(tab.charId)"
            >
            </q-btn>
          </div>
        </q-tab>
      </q-tabs>

      <q-tab-panels
        v-if="stalkThreadCount != 0"
        keep-alive
        v-model="stalkThreadActive"
        style="background: unset"
      >
        <q-tab-panel
          class="no-padding"
          v-for="tab in stalkThreads"
          :key="tab.charId"
          :name="tab.charId"
        >
          <Stalking
            :charId="tab.charId"
            :cfg="cfg"
            :stalkNow="tab.stalkNow || false"
            @stalkDone="stalkDone"
          ></Stalking>
        </q-tab-panel>
      </q-tab-panels>
    </q-page-container>

    <!-- Intro Dialogs -->
    <q-page-sticky
      v-if="introSkipped == 0"
      position="top-right"
      :offset="$q.screen.gt.xs ? [200, 12] : [50, 12]"
      style="z-index: 9000"
    >
      <Intro1Dialog @skipped="setSkipIntro"></Intro1Dialog>
    </q-page-sticky>
    <q-page-sticky
      v-if="introSkipped == 1"
      position="top-left"
      :offset="[38, 4]"
      style="z-index: 9000"
    >
      <Intro2Dialog @skipped="setSkipIntro"></Intro2Dialog>
    </q-page-sticky>
    <q-page-sticky
      v-if="introSkipped == 2 && stalkThreads.length != 0"
      position="top-left"
      :offset="[36, 36]"
      style="z-index: 9000"
    >
      <Intro3Dialog @skipped="setSkipIntro"></Intro3Dialog>
    </q-page-sticky>
  </q-layout>
</template>

<script>
import { ref, computed } from "vue";
import { useQuasar } from "quasar";
import MenuDialog from "./components/MenuDialog.vue";
import AboutDialog from "./components/AboutDialog.vue";
import Stalking from "./components/Stalking.vue";
import IntroCarousel from "./components/IntroCarousel.vue";
import Intro1Dialog from "./components/Intro1Dialog.vue";
import Intro2Dialog from "./components/Intro2Dialog.vue";
import Intro3Dialog from "./components/Intro3Dialog.vue";
import { getCharApi } from "@/composables/api.js";
import { recordApiCols } from "@/data/recordApiCols_v3.js";
import { queryIntervalOptions } from "@/data/queryIntervalOptions.js";

export default {
  name: "BNSStalker",
  components: {
    MenuDialog,
    AboutDialog,
    Stalking,
    IntroCarousel,
    Intro1Dialog,
    Intro2Dialog,
    Intro3Dialog,
  },

  setup() {
    const $q = useQuasar();
    // Menu / Intro Dialog
    const openAbout = ref(false);
    const openMenu = ref(false);
    const introSkipped = ref(
      Number(JSON.parse(localStorage.getItem("bstk-introSkipped"))) || 0
    );
    const setSkipIntro = (index) => {
      introSkipped.value = index;
      localStorage.setItem("bstk-introSkipped", index);
    };

    // Header & InputBox
    const stalkIdInput = ref("");
    const stalkIdInputExpand = ref(false);

    const startStalkLoading = ref(false);
    const startStalking = async () => {
      startStalkLoading.value = true;
      if (!stalkIdInput.value || stalkIdInput.value == "") {
        // Input error or empty
      } else {
        let charId = stalkIdInput.value;
        if (
          stalkThreads.value.filter(
            (tab) => tab.charId.toLowerCase() == charId.toLowerCase()
          ).length > 0
        ) {
          // Input duplicate
          $q.notify({
            message: `大俠醒醒好嗎，我們早就派人去跟蹤 ${stalkIdInput.value} 了`,
            type: "negative",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
            progress: true,
          });
        } else {
          // Check if character exist & correct char name
          await getCharApi(stalkIdInput.value).then((res) => {
            let charExist = res.charExist;
            if (!charExist) {
              $q.notify({
                message: `大俠我們找遍了整個江湖都沒聽說過有 ${stalkIdInput.value} 這個人`,
                type: "negative",
                icon: "report_problem",
                position: "top",
                timeout: 2500,
                progress: true,
              });
            } else {
              let charName = res.info.name;
              stalkThreads.value.push({
                charId: charName,
                lastStalkT: (+new Date() / 1000) | 0,
              });
              stalkThreadActive.value = charName;
              $q.notify({
                message: `大俠我們馬上派人去跟蹤 ${stalkIdInput.value}`,
                color: "primary",
                icon: "visibility",
                position: "top",
                timeout: 2500,
                progress: true,
              });
            }
          });
        }
      }
      // Reset input bar state
      stalkIdInput.value = "";
      startStalkLoading.value = false;
    };

    const stalkDone = (charId) => {
      stalkThreads.value.find((tab) => tab.charId == charId).stalkNow = false;
      //let now = (+new Date() / 1000) | 0;
      //stalkThreads.value.find((tab) => tab.charId == charId).lastStalkT = now;
    };

    const stopStalking = (charId) => {
      if (stalkThreads.value.length == 1) {
        stalkThreadActive.value = "";
      } else if (stalkThreadActive.value === charId) {
        stalkThreads.value.forEach((tab, i) => {
          if (tab.charId === charId) {
            let nextTab =
              stalkThreads.value[i + 1] || stalkThreads.value[i - 1];
            stalkThreadActive.value = nextTab.charId;
          }
        });
      }
      stalkThreads.value = stalkThreads.value.filter(
        (tab) => tab.charId !== charId
      );
    };

    // StalkThread
    const stalkThreads = ref([]);
    const stalkThreadActive = ref("");
    const stalkThreadCount = computed(() => {
      return stalkThreads.value.length;
    });

    //Timers
    const stalkCD = ref("");
    const updateStalkCD = () => {
      if (stalkThreads.value.length == 0) {
        return "";
      } else {
        let active = stalkThreadActive.value;
        let last = stalkThreads.value.find(
          (tab) => tab.charId == active
        ).lastStalkT;
        let next = last + cfg.value.queryInterval;
        let now = (+new Date() / 1000) | 0;
        let cd = next - now;
        return `跟蹤 ${active} 的探子<br />將在${cd}秒後回報`;
      }
    };
    stalkCD.value = updateStalkCD();

    setInterval(() => {
      stalkThreads.value.forEach((thread) => {
        let now = (+new Date() / 1000) | 0;
        if (now - thread.lastStalkT >= cfg.value.queryInterval) {
          thread.stalkNow = true;
          thread.lastStalkT = now;
        }
      });
      stalkCD.value = updateStalkCD();
    }, 1000);

    // Config
    const recordTableCols = computed(() => {
      return recordApiCols.filter((col) => col.allowDisplay);
    });

    let queryInterval =
      parseInt(localStorage.getItem("bstk-queryInterval")) || 300;
    if (
      queryIntervalOptions.value.filter(
        (option) => option.value == queryInterval
      ).length == 0
    ) {
      queryInterval = 300;
      localStorage.setItem("bstk-queryInterval", queryInterval);
    }

    const cfg = ref({
      maxStalkThread: 5,
      queryInterval: queryInterval,
      queryGetHistory: 20,
      showCols:
        JSON.parse(localStorage.getItem("bstk-showCols")) ||
        Object.values(recordTableCols.value).map((col) => col.name),
    });

    return {
      openAbout,
      openMenu,
      introSkipped,
      setSkipIntro,

      stalkIdInput,
      stalkIdInputExpand,
      startStalkLoading,
      startStalking,

      stalkDone,
      stopStalking,

      stalkThreads,
      stalkThreadActive,
      stalkThreadCount,

      stalkCD,
      updateStalkCD,

      recordTableCols,
      cfg,
    };
  },
};
</script>

<style lang="scss">
.bstk-stalkIdInputBox {
  transition: width 0.36s;
  width: 300px;
  &.expand {
    width: 380px;
  }
}
.bstk-startStalkingBtn {
  span {
    font-size: 15px;
    letter-spacing: 2px;
    padding-left: 2px;
  }
}
.bstk-stalkCD {
  font-size: 12px;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
}
</style>
