import date from 'quasar/src/utils/date.js';;

export const recordApiCols = [
  {
    name: "t",
    label: "查詢時間",
    field: "t",
    format: (val) => date.formatDate(val * 1000, "YYYYMMDD HH:mm"),
    required: true,
    align: "center",
    allowDisplay: false,
  },
  // Online info
  {
    name: "info.online",
    label: "角色上線",
    field: (row) => (row.info.online ? "線上" : "離線"),
    align: "center",
    allowDisplay: true,
  },
  {
    name: "info.account_online",
    label: "帳號上線",
    field: (row) =>
      row.info.account_online ? row.info.account_online : "離線",
    align: "center",
    allowDisplay: true,
  },
  {
    name: "info.location",
    label: "人物所在地區",
    field: (row) => row.info.geo_zone_name,
    align: "center",
    allowDisplay: true,
  },
  // Equipment
  {
    name: "equip.weapon.name",
    label: "武器",
    field: (row) => (row.equip.hand ? row.equip.hand.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "enchantgem",
        objRef: "equip.hand.detail.added_enchant_gems",
      },
      {
        type: "gem",
        objRef: "equip.hand.detail.added_gems",
      },
      {
        type: "psyche",
        objRef: "equip.hand.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.weapon.durability",
    label: "武器耐久",
    field: (row) => (row.equip.hand ? row.equip.hand.detail.durability : "-"),
    align: "center",
    allowDisplay: true,
  },
  {
    name: "equip.ring.name",
    label: "戒指",
    field: (row) =>
      row.equip.finger_left ? row.equip.finger_left.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.finger_left.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.earring.name",
    label: "耳環",
    field: (row) =>
      row.equip.ear_left ? row.equip.ear_left.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.ear_left.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.necklace.name",
    label: "項鍊",
    field: (row) => (row.equip.neck ? row.equip.neck.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.neck.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.bracelet.name",
    label: "手鐲",
    field: (row) =>
      row.equip.bracelet ? row.equip.bracelet.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.bracelet.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.belt.name",
    label: "腰帶",
    field: (row) => (row.equip.belt ? row.equip.belt.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.belt.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.gloves.name",
    label: "護拳",
    field: (row) =>
      row.equip.gloves ? row.equip.gloves.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.gloves.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soul.name",
    label: "魂",
    field: (row) => (row.equip.soul ? row.equip.soul.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.soul.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.heart.name",
    label: "靈",
    field: (row) =>
      row.equip.soul_2 ? row.equip.soul_2.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.soul_2.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.pet.name",
    label: "守護石",
    field: (row) => (row.equip.pet ? row.equip.pet.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "gem",
        objRef: "equip.pet.detail.added_gems",
      },
      {
        type: "psyche",
        objRef: "equip.pet.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.nova.name",
    label: "星",
    field: (row) => (row.equip.nova ? row.equip.nova.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.nova.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.mysticbadge.name",
    label: "祕功牌",
    field: (row) =>
      row.equip.swift_badge ? row.equip.swift_badge.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.swift_badge.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soulbadge.name",
    label: "神功牌",
    field: (row) =>
      row.equip.soul_badge ? row.equip.soul_badge.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "psyche",
        objRef: "equip.soul_badge.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.clothes1.name",
    label: "服裝",
    field: (row) => (row.equip.body ? row.equip.body.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
  },
  {
    name: "equip.clothes2.name",
    label: "服飾",
    field: (row) =>
      row.equip.body_accessory
        ? row.equip.body_accessory.detail.item.name
        : "-",
    align: "center",
    allowDisplay: true,
  },
  {
    name: "equip.clothes3.name",
    label: "頭飾",
    field: (row) => (row.equip.head ? row.equip.head.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
  },
  {
    name: "equip.clothes4.name",
    label: "臉飾",
    field: (row) => (row.equip.eye ? row.equip.eye.detail.item.name : "-"),
    align: "center",
    allowDisplay: true,
  },
  {
    name: "equip.soulshield_1",
    label: "八卦牌1",
    field: (row) =>
      row.equip.soulshield_1 ? row.equip.soulshield_1.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "soulshield",
        objRef: "equip.soulshield_1.detail.item",
      },
      {
        type: "psyche",
        objRef: "equip.soulshield_1.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soulshield_2",
    label: "八卦牌2",
    field: (row) =>
      row.equip.soulshield_2 ? row.equip.soulshield_2.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "soulshield",
        objRef: "equip.soulshield_2.detail.item",
      },
      {
        type: "psyche",
        objRef: "equip.soulshield_2.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soulshield_3",
    label: "八卦牌3",
    field: (row) =>
      row.equip.soulshield_3 ? row.equip.soulshield_3.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "soulshield",
        objRef: "equip.soulshield_3.detail.item",
      },
      {
        type: "psyche",
        objRef: "equip.soulshield_3.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soulshield_4",
    label: "八卦牌4",
    field: (row) =>
      row.equip.soulshield_4 ? row.equip.soulshield_4.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "soulshield",
        objRef: "equip.soulshield_4.detail.item",
      },
      {
        type: "psyche",
        objRef: "equip.soulshield_4.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soulshield_5",
    label: "八卦牌5",
    field: (row) =>
      row.equip.soulshield_5 ? row.equip.soulshield_5.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "soulshield",
        objRef: "equip.soulshield_5.detail.item",
      },
      {
        type: "psyche",
        objRef: "equip.soulshield_5.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soulshield_6",
    label: "八卦牌6",
    field: (row) =>
      row.equip.soulshield_6 ? row.equip.soulshield_6.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "soulshield",
        objRef: "equip.soulshield_6.detail.item",
      },
      {
        type: "psyche",
        objRef: "equip.soulshield_6.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soulshield_7",
    label: "八卦牌7",
    field: (row) =>
      row.equip.soulshield_7 ? row.equip.soulshield_7.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "soulshield",
        objRef: "equip.soulshield_7.detail.item",
      },
      {
        type: "psyche",
        objRef: "equip.soulshield_7.detail.item_spirit",
      },
    ],
  },
  {
    name: "equip.soulshield_8",
    label: "八卦牌8",
    field: (row) =>
      row.equip.soulshield_8 ? row.equip.soulshield_8.detail.item.name : "-",
    align: "center",
    allowDisplay: true,
    suppInfo: [
      {
        type: "soulshield",
        objRef: "equip.soulshield_8.detail.item",
      },
      {
        type: "psyche",
        objRef: "equip.soulshield_8.detail.item_spirit",
      },
    ],
  },
  // Stats
  {
    name: "stats.total_ability.attack_power_value",
    label: "攻擊力",
    field: (row) => row.stats.total_ability.attack_power_value,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.boss_attack_power_value",
    label: "降魔攻擊力",
    field: (row) => row.stats.total_ability.boss_attack_power_value,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.attack_critical_value",
    label: "暴擊傷害",
    field: (row) => row.stats.total_ability.attack_critical_value,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.attack_critical_damage_rate",
    label: "暴擊傷害量",
    field: (row) => row.stats.total_ability.attack_critical_damage_rate,
    format: (val) => `${val}%`,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.abnormal_attack_power_value",
    label: "狀態異常傷害",
    field: (row) => row.stats.total_ability.abnormal_attack_power_value,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.abnormal_attack_power_rate",
    label: "狀態異常傷害率",
    field: (row) => row.stats.total_ability.abnormal_attack_power_rate,
    format: (val) => `${val}%`,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.attack_attribute_value",
    label: "功力",
    field: (row) => row.stats.total_ability.attack_attribute_value,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.attack_attribute_rate",
    label: "功力傷害率",
    field: (row) => row.stats.total_ability.attack_attribute_rate,
    format: (val) => `${val}%`,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.int_max_hp",
    label: "生命力",
    field: (row) => row.stats.total_ability.int_max_hp,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.total_ability.int_defend_power_value",
    label: "防禦力",
    field: (row) => row.stats.total_ability.int_defend_power_value,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.point_ability.offense_point",
    label: "洪門攻擊點",
    field: (row) => row.stats.point_ability.offense_point,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.point_ability.defense_point",
    label: "洪門防禦點",
    field: (row) => row.stats.point_ability.defense_point,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.point_ability.picks[0].point",
    label: "仇恨",
    field: (row) => row.stats.point_ability.picks[0].point,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.point_ability.picks[1].point",
    label: "再生",
    field: (row) => row.stats.point_ability.picks[1].point,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.point_ability.picks[2].point",
    label: "跑速",
    field: (row) => row.stats.point_ability.picks[2].point,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.point_ability.picks[3].point",
    label: "洪門志氣",
    field: (row) => row.stats.point_ability.picks[3].point,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "stats.point_ability.picks[4].point",
    label: "異常狀態",
    field: (row) => row.stats.point_ability.picks[4].point,
    align: "center",
    allowDisplay: true,
  },
  // Training
  {
    name: "training.talentStyle",
    label: "技能派系",
    field: (row) => row.training.talentStyle,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "training.talentSelection",
    label: "技能特性",
    field: (row) => row.training.talentSelection,
    align: "center",
    allowDisplay: true,
  },
  // Info
  {
    name: "info.account_name",
    label: "帳號名稱",
    field: (row) => row.info.account_name,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "info.class_name",
    label: "職業",
    field: (row) => row.info.class_name,
    align: "center",
    allowDisplay: false,
  },
  {
    name: "info.level",
    label: "等級",
    field: (row) => row.info.level,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "info.mastery_level",
    label: "星等",
    field: (row) => row.info.mastery_level,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "info.server_name",
    label: "伺服器",
    field: (row) => row.info.server_name,
    align: "center",
    allowDisplay: false,
  },
  {
    name: "info.faction_name",
    label: "勢力",
    field: (row) => row.info.faction_name,
    align: "center",
    allowDisplay: true,
  },
  {
    name: "info.guild.guild_name",
    label: "門派",
    field: (row) => (row.info.guild ? row.info.guild.guild_name : "-"),
    align: "center",
    allowDisplay: true,
  },
  {
    name: "info.gender_name",
    label: "性別",
    field: (row) => row.info.gender_name,
    align: "center",
    allowDisplay: true,
  },
];
