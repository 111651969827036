<template>
  <q-card class="bg-primary text-white" style="min-width: 205px">
    <q-card-section class="q-pb-none q-pr-sm">
      <div class="row items-center no-wrap">
        <div class="col text-grey-3">
          <div class="text-h6" style="line-height: 1.8rem">跟蹤入門101</div>
          <div class="text-subtitle2" style="line-height: 1.25rem">
            這裡輸入目標名稱就可以派人去跟蹤了
          </div>
        </div>
        <div class="col-auto q-pl-sm" style="margin-top: -18px">
          <q-icon
            flat
            round
            size="lg"
            color="white"
            :name="$q.screen.gt.xs ? 'north_east' : 'arrow_upward'"
          />
        </div>
      </div>
    </q-card-section>
    <q-card-actions align="right">
      <q-btn dense outline color="grey-3" @click="skipIntro()">
        老師我懂了！
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  name: "Intro1Dialog",
  emits: ["skipped"],
  setup(prop, { emit }) {
    const skipIntro = () => {
      let introIndex = 1;
      emit("skipped", introIndex);
    };
    return {
      skipIntro,
    };
  },
};
</script>
