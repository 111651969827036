<template>
  <q-table
    flat
    dense
    hide-bottom
    separator="none"
    table-header-style="border-bottom: 1px solid rgba(255, 255, 255, 0.28);"
    table-style="color: rgba(255, 255, 255, 0.8);"
    :rows-per-page-options="[0]"
    id="bstk-recordTable"
    :columns="recordApiCols"
    :visible-columns="cfg.showCols"
    :rows="recordsArray"
    row-key="t"
  >
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th>詳細</q-th>
        <q-th v-for="col in props.cols" :key="col.name">
          {{ col.label }}
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td auto-width>
          <q-btn
            dense
            size="7px"
            color="primary"
            style="display: block; margin: auto"
            @click="props.expand = !props.expand"
            :icon="props.expand ? 'expand_less' : 'expand_more'"
          />
        </q-td>
        <q-td v-for="col in props.cols" :key="col.name" class="text-center">
          {{ col.value }}
        </q-td>
      </q-tr>
      <q-tr v-if="props.expand" :props="props">
        <q-td></q-td>
        <q-td
          v-for="col in props.cols"
          :key="col.name"
          class="no-padding"
          style="vertical-align: top"
        >
          <template v-for="supp in col.suppInfo" :key="supp.type">
            <div
              v-if="supp.type == 'gem' || supp.type == 'enchantgem'"
              class="bstk-tableSuppInfo"
            >
              <template
                v-for="gem in getColValueByRef(props.row, supp.objRef)"
                :key="gem.name"
              >
                <div>{{ disambiguateGemName(gem) }}</div>
              </template>
            </div>
            <div v-if="supp.type == 'soulshield'" class="bstk-tableSuppInfo">
              <template
                v-for="ss in getColValueByRef(props.row, supp.objRef)"
                :key="ss.name"
              >
                <div v-for="attr in ss.sub_abilities" :key="attr.name">
                  {{ attr.name }} {{ (attr.origin_value / 10000).toFixed(0) }}%
                </div>
              </template>
            </div>
            <div v-if="supp.type == 'psyche'" class="bstk-tableSuppInfo">
              <template
                v-for="psyche in getColValueByRef(props.row, supp.objRef)"
                :key="psyche.name"
              >
                <div>{{ psyche.name }}</div>
                <div v-for="attr in psyche.abilities" :key="attr.name">
                  {{ attr.name }} {{ attr.value }}
                </div>
              </template>
            </div>
          </template>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import { watch } from "vue";
import { getCharApi } from "@/composables/api.js";
import { writeRecordToDB, syncRecordFromDB } from "@/composables/database.js";
import { recordApiCols } from "@/data/recordApiCols_v3.js";

export default {
  name: "Stalking",
  props: {
    charId: {
      type: String,
      required: true,
    },
    cfg: {
      type: Object,
      require: true,
    },
    stalkNow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["stalkDone"],
  setup(props, { emit }) {
    // Display helper functions
    const getColValueByRef = (obj, refs) => {
      refs = refs.split(".");
      refs.forEach((ref) => {
        if (!obj) {
          return [];
        }
        ref = ref.split("[");
        obj = obj[ref[0]];
        if (ref.length > 1) {
          if (!obj) {
            return [];
          }
          obj = obj[parseInt(ref[1])];
        }
      });
      if (!obj) {
        return [];
      }
      if (!Array.isArray(obj)) {
        return [obj];
      }
      return obj;
    };

    // Show gem names with specific subtype
    const disambiguateGemName = (gem) => {
      let name = gem.name;
      if (name.includes("黑曜") || name.includes("黑榴")) {
        if (gem.sub_abilities[0].name.includes("功力")) {
          name += "(功力)";
        } else if (gem.sub_abilities[0].name.includes("異常")) {
          name += "(異常)";
        }
      } else if (name.includes("石榴")) {
        if (gem.sub_abilities[0].name.includes("暴擊傷害")) {
          name += "(暴傷)";
        } else if (gem.sub_abilities[0].name.includes("異擊防禦")) {
          name += "(暴防)";
        }
      }
      return name;
    };

    // Establish sync from DB
    const recordsArray = syncRecordFromDB(
      props.charId,
      props.cfg.queryGetHistory
    );

    const getApi = async () => {
      await getCharApi(props.charId).then((res) => {
        writeRecordToDB(res).then((res) => {
          console.log(res);
        });
      });
      return;
    };
    getApi();

    // Execute stalk now
    watch(
      () => props.stalkNow,
      () => {
        if (props.stalkNow) {
          getApi().then(() => {
            emit("stalkDone", props.charId);
          });
        }
      }
    );

    return {
      recordApiCols,
      getColValueByRef,
      disambiguateGemName,

      recordsArray,
      getApi,
    };
  },
};
</script>

<style lang="scss">
#bstk-recordTable {
  height: calc(100vh - 86px);
  background: unset !important;

  // 1st & 2nd column
  th,
  td {
    &:first-child,
    &:nth-child(2) {
      background-color: var(--q-dark-page);
    }
    &:first-child {
      padding-left: 8px;
    }
    &:nth-child(2) {
      border-right: 1px solid rgba(255, 255, 255, 0.28);
    }
  }
  // 1st row
  tr th {
    background-color: var(--q-dark-page);
    border-bottom: 1px solid rgba(255, 255, 255, 0.28);
    position: sticky;
    z-index: 2;
  }
  // loading indicator
  thead tr:last-child th {
    // height of all previous header rows */
    top: 28px;
    z-index: 3;
  }
  thead tr:first-child th {
    top: 0;
    z-index: 1;
  }
  tr:first-child th:first-child,
  tr:first-child th:nth-child(2) {
    z-index: 3;
  }
  td:first-child,
  td:nth-child(2) {
    z-index: 1;
  }
  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
  td:nth-child(2),
  th:nth-child(2) {
    position: sticky;
    left: 40px;
  }

  .bstk-tableSuppInfo {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding: 0px 8px 4px 8px;
  }
}
</style>
