<template>
  <q-card style="width: 80vw; max-width: 900px">
    <q-toolbar>
      <q-avatar>
        <q-icon name="menu_book" size="sm"></q-icon>
      </q-avatar>
      <q-toolbar-title>關於劍靈跟蹤狂</q-toolbar-title>
      <q-btn flat round dense icon="close" v-close-popup />
    </q-toolbar>
    <q-card-section class="no-padding">
      <div class="bstk-aboutItem">
        <div class="bstk-aboutHeader fontHeader">WTF?</div>
        <div class="bstk-aboutContent fontCaption">
          某天貓頭路過一部落看到有原始人瘋狂按F5，細探之下發現原始人其實在偷窺，於是貓頭看不下去決定變身貓老師，現教授劍靈黑科技課程201：「如何當一個跟蹤狂？」。
        </div>
      </div>
      <div class="bstk-aboutItem">
        <div class="bstk-aboutHeader fontHeader">版本記錄</div>
        <div class="bstk-aboutContent">
          <q-virtual-scroll style="max-height: 360px" :items="updateLog">
            <template v-slot="{ item }">
              <div class="bstk-updateItem">
                <div class="fontHeader">v{{ item.v }} /{{ item.date }}</div>
                <div class="fontCaption">
                  <div v-for="(line, i) in item.details" :key="i">
                    {{ line }}
                  </div>
                </div>
              </div>
            </template>
          </q-virtual-scroll>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { updateLog } from "@/data/updateLog.js";

export default {
  name: "AboutDialog",
  setup() {
    return {
      updateLog,
    };
  },
};
</script>
<style lang="scss">
.bstk-aboutItem {
  margin: 16px;
  .bstk-aboutHeader {
    padding: 4px 0;
  }
  .bstk-aboutContent {
    padding-left: 12px;
  }
  .bstk-updateItem {
    margin-bottom: 12px;
  }
  .fontHeader {
    color: rgba(255, 255, 255, 0.64);
    font-size: 0.875rem;
    line-height: 1.2rem;
    letter-spacing: 0.01786em;
  }
  .fontCaption {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0.03333em;
  }
}
</style>
