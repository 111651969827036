<template>
  <q-card style="width: 70vw; max-width: 600px">
    <q-toolbar>
      <q-avatar>
        <q-icon name="settings" size="sm"></q-icon>
      </q-avatar>
      <q-toolbar-title>設定</q-toolbar-title>
      <q-btn flat round dense icon="done_outline" color="green" v-close-popup />
    </q-toolbar>
    <q-card-section class="q-pt-none">
      <q-list dense>
        <q-item-label caption class="q-py-none q-px-md">
          所有設定將自動套用及儲存，但只套用到這個瀏覽器
        </q-item-label>
        <q-item dense style="padding-left: 0px">
          <q-item-section>
            <q-item-label header class="q-pl-sm q-pb-none"
              >自動查詢間隔時間</q-item-label
            >
            <q-item-label caption class="q-px-md">
              自動查詢只會有追蹤分頁開啟時執行
            </q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-select
              dense
              filled
              class="q-pt-md"
              v-model="queryInterval"
              :options="queryIntervalOptions"
              emit-value
              map-options
            />
          </q-item-section>
        </q-item>
        <q-item-label header class="q-px-sm q-pb-sm q-pt-md">
          查詢記錄顯示欄位
        </q-item-label>
        <q-virtual-scroll style="max-height: 280px" :items="recordTableCols">
          <template v-slot="{ item, index }">
            <q-item
              dense
              clickable
              v-ripple
              :key="index"
              @click="toggleShowCols(item.name)"
            >
              <q-item-section>{{ item.label }}</q-item-section>
              <q-item-section side>
                <q-checkbox
                  dense
                  color="secondary"
                  v-model="showCols"
                  :val="item.name"
                  @click="updateShowCols()"
                ></q-checkbox>
              </q-item-section>
            </q-item>
          </template>
        </q-virtual-scroll>
      </q-list>
    </q-card-section>
  </q-card>
</template>

<script>
import { ref, watch } from "vue";
import { queryIntervalOptions } from "@/data/queryIntervalOptions.js";
import { recordApiCols } from "@/data/recordApiCols_v3.js";

export default {
  name: "MenuDialog",
  props: ["cfg"],
  emits: ["update:cfg"],
  setup(props, { emit }) {
    const queryInterval = ref(props.cfg.queryInterval);
    watch(queryInterval, (newValue) => {
      updateCfg("queryInterval", newValue);
    });

    // showCols
    const recordTableCols = recordApiCols.filter((col) => col.allowDisplay);
    const showCols = ref(props.cfg.showCols);
    const toggleShowCols = (name) => {
      let i = showCols.value.indexOf(name);
      if (i === -1) {
        showCols.value.push(name);
      } else {
        showCols.value.splice(i, 1);
      }
      updateShowCols();
    };
    const updateShowCols = () => {
      updateCfg("showCols", showCols.value);
    };

    const updateCfg = (field, newVal) => {
      let cfgRaw = props.cfg;
      cfgRaw[field] = newVal;
      localStorage.setItem("bstk-" + field, JSON.stringify(newVal));
      emit("update:cfg", cfgRaw);
    };

    return {
      queryIntervalOptions,
      queryInterval,

      recordTableCols,
      showCols,
      toggleShowCols,
      updateShowCols,

      updateCfg,
    };
  },
};
</script>
