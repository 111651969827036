import "./styles/quasar.scss";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... }) (main.js)
export default {
  config: {
    dark: true,
  },
  plugins: [Notify],
};
