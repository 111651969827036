export const updateLog = [
  {
    v: "1.3",
    date: "20210922",
    details: [
      "更新：改用劍靈蜂窩角色查詢API v3",
      "更新：v1.3前的跟蹤報告全部移除",
      "功能：跟蹤記錄新增角色/帳號上線狀態、角色所在地區、技能派系/特性及帳號名稱欄位",
      "功能：黑榴、黑曜、石榴系列的寶石及寵物寶石新增副屬性資料",
      "更改：自動跟蹤間隔時間現在由上一次跟蹤開始時就開始計算",
      "更改：重製跟蹤狂的LOGO",
    ],
  },
  {
    v: "1.2",
    date: "20210919",
    details: [
      "更新：支援台服UE4版本角色查詢API",
      "更新：UE3版本的跟蹤報告全部移除",
      "功能：跟蹤記錄新增星等及性別欄位",
      "功能：武器欄位的詳細資料中新增元氣石、真氣石、武器精氣資訊",
    ],
  },
  {
    v: "1.1a",
    date: "20210903",
    details: [
      "修正：自動查詢間隔時間設定將可以正常儲存",
      "修正：展開沒有裝備武器/守護石的跟蹤報告時將不會跳錯誤訊息",
      "修正：沒有屬性的八卦牌將不再會於展開跟蹤報告時顯示錯誤內容",
    ],
  },
  {
    v: "1.1",
    date: "20210829",
    details: [
      "功能：新增關於視窗及版本記錄",
      "功能：新增教學提示框（跟蹤入門102及103）",
      "功能：現在會在頁面頂端顯示探子下一次回報的時間（若多位大俠同時派人跟蹤目標，回報時間有可能提早）",
      "更改：改善通知框的顯示時機及內容",
      "更改：自動跟蹤間隔時間現在由上一次跟蹤結束後才開始計算",
      "修正：寵物寶石資料將在跟蹤記錄中正確顯示",
      "修正：更改自動跟蹤間隔時間設定將立即生效",
      "修正：輸入目標名稱後將先確定目標存在才會開始跟蹤",
      "修正：開始跟蹤時將自動檢查目標名稱中的字母大小寫是否錯誤",
    ],
  },
  {
    v: "1.0",
    date: "20210827",
    details: [
      "推出：第一個正式版本",
      "功能：自動定時跟蹤並記錄（可自訂間隔時間）",
      "功能：同時跟蹤多個角色（最多5個）",
      "功能：跟蹤記錄將上傳至資料庫永久備份",
      "功能：自訂跟蹤記錄顯示哪些欄位",
    ],
  },
];
