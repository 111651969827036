import axios from "axios";

export const getCharApi = async (charId) => {
  const apiUrl = "https://archive.bnshive.com/api/character_v3/";
  try {
    let res = await axios.get(`${apiUrl}${charId}`);
    return res.data;
  } catch (err) {
    console.log("[API] Failed to get data:\n", err);
    return null;
  }
};
