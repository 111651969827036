<template>
  <q-card class="bg-primary text-white" style="min-width: 205px">
    <q-card-section class="q-pb-none q-pl-sm">
      <div class="row items-center no-wrap">
        <div class="col-auto q-pr-sm" style="margin-top: -18px">
          <q-icon flat round size="lg" color="white" name="arrow_upward" />
        </div>
        <div class="col text-grey-3">
          <div class="text-h6" style="line-height: 1.8rem">跟蹤入門102</div>
          <div class="text-subtitle2" style="line-height: 1.25rem">
            這裡可以命令探子選擇性地回報內容
          </div>
          <div class="text-subtitle2" style="line-height: 1.25rem">
            篩選資料有助您更好地分析跟蹤報告
          </div>
        </div>
      </div>
    </q-card-section>
    <q-card-actions align="right">
      <q-btn dense outline color="grey-3" @click="skipIntro()">
        老師我懂了！
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  name: "Intro2Dialog",
  emits: ["skipped"],
  setup(prop, { emit }) {
    const skipIntro = () => {
      let introIndex = 2;
      emit("skipped", introIndex);
    };
    return {
      skipIntro,
    };
  },
};
</script>
